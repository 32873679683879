var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Card",
    {
      style: { backgroundColor: _vm.backgroundColor },
      attrs: { padding: 0, bordered: _vm.bordered },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-content card1",
          style: { backgroundImage: _vm.backgroundImage },
        },
        [
          _c(
            "div",
            { staticClass: "card-body", style: { height: _vm.cardHeight } },
            [
              _vm.icon
                ? _c("Icon", {
                    attrs: {
                      type: _vm.icon,
                      color: _vm.iconColor,
                      size: _vm.iconSize,
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: _vm.image,
                      width: _vm.width,
                      height: _vm.height,
                    },
                  }),
              _c(
                "div",
                [
                  _c("count-up", {
                    attrs: {
                      prefix: _vm.prefix,
                      suffix: _vm.suffix,
                      id: _vm.id,
                      "end-val": _vm.endVal,
                      color: _vm.countColor,
                      countSize: _vm.countSize,
                      countWeight: _vm.countWeight,
                      decimalPlaces: _vm.decimalPlaces,
                      duration: _vm.duration,
                      delay: _vm.delay,
                      options: _vm.options,
                    },
                  }),
                  _c(
                    "span",
                    {
                      style: {
                        color: _vm.titleColor,
                        fontSize: _vm.titleSize,
                        fontWeight: _vm.titleWeight,
                      },
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }